import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AnonymousLayoutComponent } from 'src/app/layout/anonymous-layout/anonymous-layout.component';
import { AppLayoutComponent } from 'src/app/layout/app-layout/app-layout.component';
import { IsAuthenticatedGuard } from 'src/app/_shared/guards/route.guards';
import { TemporaryMaintenanceComponent } from './temporary-maintenance/temporary-maintenance.component';
import { Maintenance } from 'src/environments/temporary-maintenance';

let routes: Routes;

if (Maintenance) {
  routes = [
    {
      path: '**',
      redirectTo: '',
    },
    {
      path: '',
      component: TemporaryMaintenanceComponent,
    },
  ];
} else {
  routes = [
    {
      path: '',
      canActivate: [IsAuthenticatedGuard],
      runGuardsAndResolvers: 'always',
      component: AppLayoutComponent,
      children: [
        { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
        {
          path: 'dashboard',
          loadChildren: (): Promise<any> => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        },
        {
          path: 'charts',
          loadChildren: (): Promise<any> => import('./charts/charts.module').then((c) => c.ChartsModule),
        },
        {
          path: 'charts/:mpId/:accountId/:alarmId',
          loadChildren: (): Promise<any> => import('./charts/charts.module').then((c) => c.ChartsModule),
        },
        {
          path: 'energy-power',
          loadChildren: (): Promise<any> =>
            import('./energy-power/energy-power.module').then((m) => m.EnergyPowerModule),
        },
        {
          path: 'hf-emissions',
          loadChildren: (): Promise<any> =>
            import('./hf-emissions/hf-emissions.module').then((m) => m.HfEmissionsModule),
        },
        {
          path: 'reports',
          loadChildren: (): Promise<any> =>
            import('./reports-recommendations/reports-recommendations.module').then(
              (m) => m.ReportsRecommendationsModule
            ),
        },
        {
          path: 'report-writer',
          loadChildren: (): Promise<any> =>
            import('./report-writer/report-writer.module').then((m) => m.ReportWriterModule),
        },
        {
          path: 'monthly-report/:accountId/:mpId/:year/:month',
          redirectTo: '/reports/monthly/:accountId/:mpId/:year/:month',
        },
        {
          path: 'fleet',
          loadChildren: (): Promise<any> =>
            import('./fleet-overview/fleet-overview.module').then((m) => m.FleetOverviewModule),
        },
        {
          path: 'event-graph/:mpId/:accountId/:deviceEventId/:deviceEventTypeId/:triggeredWhen',
          loadChildren: (): Promise<any> => import('./event-graph/event-graph.module').then((m) => m.EventGraphModule),
        },
        {
          path: 'events-list',
          loadChildren: (): Promise<any> => import('./events-list/events-list.module').then((m) => m.EventsListModule),
        },
        {
          path: 'more-information',
          loadChildren: (): Promise<any> => import('./information/information.module').then((m) => m.InformationModule),
        },
        {
          path: 'profile',
          loadChildren: (): Promise<any> => import('./profile/profile.module').then((m) => m.ProfileModule),
        },
        {
          path: 'manage-members',
          loadChildren: (): Promise<any> =>
            import('./manage-members/manage-members.module').then((m) => m.ManageMembersModule),
        },
        {
          path: 'admin',
          loadChildren: (): Promise<any> => import('./sysadmin/sysadmin.module').then((m) => m.SysadminModule),
        },
        {
          path: 'notification',
          loadChildren: (): Promise<any> =>
            import('./notification/notification.module').then((m) => m.NotificationModule),
        },
        {
          path: 'rms-variation-analysis',
          loadChildren: (): Promise<any> =>
            import('./rms-variation-analysis/rms-variation-analysis.module').then((m) => m.RmsVariationAnalysisModule),
        },
        {
          path: 'maintenance',
          loadChildren: (): Promise<any> => import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule),
        },
      ],
    },
    {
      path: '',
      component: AnonymousLayoutComponent,
      children: [
        { path: 'passwordRecovery', redirectTo: 'login/password-recovery' },
        { path: 'password-recovery', redirectTo: 'login/password-recovery' },
        {
          path: 'login',
          loadChildren: (): Promise<any> =>
            import('./qubescan-login/qubescan-login.module').then((m) => m.QubescanLoginModule),
        },
        {
          path: 'drivescan',
          loadChildren: (): Promise<any> => import('./login/login.module').then((m) => m.LoginModule),
        },
        {
          path: 'qubescan',
          redirectTo: 'login',
        },
        {
          path: 'register',
          loadChildren: (): Promise<any> => import('./activation/activation.module').then((m) => m.ActivationModule),
        },
      ],
    },
  ];
}
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
