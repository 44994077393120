import { Action } from '@ngrx/store';

import { FeaturesActions } from 'src/app/_store/_features/actions';
import {
  BannersFeature,
  Features,
  NotificationGroupFeature,
  FirmwareUpdateFeature,
  RmsAnalysisFeature,
} from 'src/app/_shared/classes/features';

export interface State extends Features {}

const initialState: State = {
  notificationGroup: {
    enabled: false,
    navButtonVisible: false,
    displayBasicAccount: false,
  },
  banners: {
    showExpirationBanner: false,
    showPremiumTrialBanner: false,
  },
  firmwareUpdate: {
    enabled: false,
    navButtonVisible: false,
    trendAlarmModel: false,
    promote: false,
    upload: false,
    showPartnerCol: false,
    showAccountCol: false,
  },
  rmsAnalysis: {
    enabled: false,
    navButtonVisible: false,
  },
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as FeaturesActions.FeaturesActionsUnion;

  switch (specificAction.type) {
    case FeaturesActions.getFeaturesSuccess.type:
      return {
        ...state,
        ...specificAction.payload,
      };
    case FeaturesActions.setFeatureBannerVisibility.type:
      const banners = { banners: { ...state.banners, ...specificAction.payload } };
      return {
        ...state,
        ...banners,
      };
    default:
      return state;
  }
}

export const getNotificationGroup = (state: State): NotificationGroupFeature => state.notificationGroup;
export const getFirmwareUpdate = (state: State): FirmwareUpdateFeature => state.firmwareUpdate;
export const getBanners = (state: State): BannersFeature => state.banners;
export const getRmsAnalysis = (state: State): RmsAnalysisFeature => state.rmsAnalysis;
