import { cloneDeep } from 'lodash';
import { mergeMap, switchMap, takeUntil, withLatestFrom, map } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { forkJoin, of, timer } from 'rxjs';
import * as moment from 'moment';
import { Store } from '@ngrx/store';

import { QubescanDashboardActions } from 'src/app/_store/_qubescan-dashboard/actions';
import { GraphManagerService } from 'src/app/_shared/services/graph-manager.service';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { UserActions } from 'src/app/_store/_user/actions';
import { ChannelsActions } from 'src/app/_store/_channels/actions';
import { MeasurementPoint } from 'src/app/_shared/classes/MeasurementPoint';
import * as fromStore from 'src/app/_store/_reducers';
import { AuthService } from 'src/app/_shared/services';

@Injectable()
export class QubescanDashboardEffects {
  startPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QubescanDashboardActions.startPollingValue.type),
      mergeMap((action) => {
        this.chartSetName = action.payload.chartSetName;
        this.timezone = action.payload.timezone;
        this.mpStatus = action.payload.mpStatus;
        this.mpId = action.payload.mpId;
        this.changeMpValue = true;
        return [QubescanDashboardActions.polling1Min(), QubescanDashboardActions.polling1MinValue()];
      })
    )
  );

  start1MinPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QubescanDashboardActions.polling1Min.type),
      switchMap(() => {
        return timer(60000, 60000).pipe(
          switchMap(() => {
            if (this.graphManager.dateRangeEnd.isBefore(moment().subtract(1, 'hour').tz(this.timezone).utc())) {
              return [QubescanDashboardActions.keepPolling()];
            } else {
              this.graphManager.dateRangeEnd = moment().tz(this.timezone).utc();
              return this.graphManager.updateChartSet(this.chartSetName, this.timezone, false, true, 'polling').pipe(
                mergeMap((qubescanValue: any) => {
                  if (
                    this.lastPacket &&
                    qubescanValue[0][0].data[qubescanValue[0][0].data.length - 1] &&
                    this.lastPacket[0] !== qubescanValue[0][0].data[qubescanValue[0][0].data.length - 1][0]
                  ) {
                    this.lastPacket = qubescanValue[0][0].data[qubescanValue[0][0].data.length - 1];
                    if (this.mpStatus <= 4) {
                      return this.mpService.getMeasurementPoint(this.mpId).pipe(
                        switchMap((mp) => {
                          mp.measurementPointId = mp.roomId;
                          return [
                            UserActions.getMpSuccess({
                              payload: {
                                mp: new MeasurementPoint(mp),
                              },
                            }),
                            ChannelsActions.setAllChannels({
                              payload: {
                                mpId: mp.measurementPointId,
                                mpTypeId: mp.measurementPointTypeId,
                              },
                            }),
                            QubescanDashboardActions.setValueSucces({
                              payload: { qubescanValue: cloneDeep(qubescanValue) },
                            }),
                            QubescanDashboardActions.getKPI({ payload: { mpId: this.mpId } }),
                            QubescanDashboardActions.polling10Min(),
                          ];
                        })
                      );
                    } else {
                      return [
                        QubescanDashboardActions.setValueSucces({
                          payload: { qubescanValue: cloneDeep(qubescanValue) },
                        }),
                        QubescanDashboardActions.getKPI({ payload: { mpId: this.mpId } }),
                        QubescanDashboardActions.polling10Min(),
                      ];
                    }
                  } else {
                    if (qubescanValue[0][0].data[qubescanValue[0][0].data.length - 1]) {
                      this.lastPacket = qubescanValue[0][0].data[qubescanValue[0][0].data.length - 1];
                    } else {
                      this.lastPacket = ['init'];
                    }
                    return [QubescanDashboardActions.keepPolling()];
                  }
                })
              );
            }
          }),
          takeUntil(
            this.actions$.pipe(
              ofType(QubescanDashboardActions.polling10Min, QubescanDashboardActions.destroyedDasboard)
            )
          )
        );
      })
    )
  );

  start1MinPollingValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QubescanDashboardActions.polling1MinValue.type),
      switchMap(() => {
        return timer(1, 60000).pipe(
          withLatestFrom(this.store.select(fromStore.getQubescanChannel)),
          switchMap((channel) => {
            let table = [];
            let oneChannel = [];
            let fiveChannel = [];
            let tenChannel = [];
            channel[1].map((channel) => {
              if (channel && table.indexOf(channel[1]) === -1) {
                table.push(channel[1]);
              }
              if (channel && channel[1] === 'oneminute') {
                oneChannel.push(channel[0]);
              } else if (channel && channel[1] === 'fiveminute') {
                fiveChannel.push(channel[0]);
              } else if (channel && channel[1] === 'tenminute') {
                tenChannel.push(channel[0]);
              }
            });

            return forkJoin([
              table.indexOf('oneminute') !== -1
                ? this.mpService.getLastMpTrends(this.mpId.toString(), oneChannel, 'oneminute')
                : of(null),
              table.indexOf('fiveminute') !== -1
                ? this.mpService.getLastMpTrends(this.mpId.toString(), fiveChannel, 'fiveminute')
                : of(null),
              table.indexOf('tenminute') !== -1
                ? this.mpService.getLastMpTrends(this.mpId.toString(), tenChannel, 'tenminute')
                : of(null),
            ]);
          }),
          switchMap(([oneValue, fiveValue, tenValue]) => {
            let qubescanChannelValue = { ...oneValue, ...fiveValue, ...tenValue };
            if (this.timestamp !== qubescanChannelValue.time_stamp || this.changeMpValue) {
              if (this.timestamp && !this.changeMpValue) {
                return [
                  QubescanDashboardActions.setChannelValueSuccess({
                    payload: { qubescanChannelValue: { ...qubescanChannelValue } },
                  }),
                  QubescanDashboardActions.polling10MinValue(),
                ];
              }

              this.timestamp = qubescanChannelValue.time_stamp;
              this.changeMpValue = false;
              return [
                QubescanDashboardActions.setChannelValueSuccess({
                  payload: {
                    qubescanChannelValue: { ...qubescanChannelValue },
                  },
                }),
              ];
            } else {
              return [
                QubescanDashboardActions.keepPollingValue(),
                QubescanDashboardActions.setLastCommunication({
                  payload: { lastCommunication: qubescanChannelValue.lastCommunicationTime?.toString() },
                }),
              ];
            }
          }),

          takeUntil(
            this.actions$.pipe(
              ofType(QubescanDashboardActions.polling10MinValue, QubescanDashboardActions.destroyedDasboard)
            )
          )
        );
      })
    )
  );

  start10MinPolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QubescanDashboardActions.polling10Min.type),
      switchMap(() => {
        return timer(10 * 60000, 10 * 60000).pipe(
          switchMap(() => {
            this.graphManager.dateRangeEnd = moment().tz(this.timezone).utc();
            return this.graphManager.updateChartSet(this.chartSetName, this.timezone, false, true, 'polling').pipe(
              mergeMap((qubescanValue: any) => {
                if (
                  this.lastPacket &&
                  qubescanValue[0][0].data[qubescanValue[0][0].data.length - 1] &&
                  this.lastPacket[0] !== qubescanValue[0][0].data[qubescanValue[0][0].data.length - 1][0]
                ) {
                  this.lastPacket = qubescanValue[0][0].data[qubescanValue[0][0].data.length - 1];
                  return [
                    QubescanDashboardActions.setValueSucces({
                      payload: {
                        qubescanValue: cloneDeep(qubescanValue),
                      },
                    }),
                    QubescanDashboardActions.getKPI({ payload: { mpId: this.mpId } }),
                  ];
                } else {
                  return [QubescanDashboardActions.polling1Min()];
                }
              })
            );
          }),
          takeUntil(
            this.actions$.pipe(ofType(QubescanDashboardActions.polling1Min, QubescanDashboardActions.destroyedDasboard))
          )
        );
      })
    )
  );

  start10MinPollingValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QubescanDashboardActions.polling10MinValue.type),
      switchMap(() => {
        return timer(10 * 60000, 10 * 60000).pipe(
          withLatestFrom(this.store.select(fromStore.getQubescanChannel)),
          switchMap((channel) => {
            let table = [];
            let oneChannel = [];
            let fiveChannel = [];
            let tenChannel = [];
            channel[1].map((channel) => {
              if (channel && table.indexOf(channel[1]) === -1) {
                table.push(channel[1]);
              }
              if (channel && channel[1] === 'oneminute') {
                oneChannel.push(channel[0]);
              } else if (channel && channel[1] === 'fiveminute') {
                fiveChannel.push(channel[0]);
              } else if (channel && channel[1] === 'tenminute') {
                tenChannel.push(channel[0]);
              }
            });

            return forkJoin([
              table.indexOf('oneminute') !== -1
                ? this.mpService.getLastMpTrends(this.mpId.toString(), oneChannel, 'oneminute')
                : of(null),
              table.indexOf('fiveminute') !== -1
                ? this.mpService.getLastMpTrends(this.mpId.toString(), fiveChannel, 'fiveminute')
                : of(null),
              table.indexOf('tenminute') !== -1
                ? this.mpService.getLastMpTrends(this.mpId.toString(), tenChannel, 'tenminute')
                : of(null),
            ]);
          }),
          switchMap(([oneValue, fiveValue, tenValue]) => {
            let qubescanChannelValue = { ...oneValue, ...fiveValue, ...tenValue };
            if (this.timestamp !== qubescanChannelValue.time_stamp) {
              this.timestamp = qubescanChannelValue.time_stamp;
              return [
                QubescanDashboardActions.setChannelValueSuccess({
                  payload: {
                    qubescanChannelValue: { ...qubescanChannelValue },
                  },
                }),
              ];
            } else {
              return [QubescanDashboardActions.polling1MinValue()];
            }
          }),
          takeUntil(
            this.actions$.pipe(
              ofType(QubescanDashboardActions.polling1MinValue, QubescanDashboardActions.destroyedDasboard)
            )
          )
        );
      })
    )
  );

  getKPI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QubescanDashboardActions.getKPI.type),
      switchMap((action) => {
        return this.mpService
          .getKPI(action.payload.mpId)
          .pipe(map((kpi) => QubescanDashboardActions.getKPISuccess({ payload: { kpi: kpi } })));
      })
    )
  );

  updateChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QubescanDashboardActions.updateChannel.type),
      withLatestFrom(this.store.select(fromStore.getQubescanChannel), this.store.select(fromStore.getUserPreferences)),
      mergeMap((action) => {
        let channel = cloneDeep(action[1]);
        channel[action[0].payload.index] = action[0].payload.qubescanChannel;
        let userPrefs = cloneDeep(action[2]);

        if (userPrefs.dashboardChannel) {
          userPrefs.dashboardChannel[this.mpId] = channel;
        } else {
          userPrefs.dashboardChannel = {
            [this.mpId]: channel,
          };
        }

        this.authService.savePreferences(userPrefs, true);
        return [
          QubescanDashboardActions.updateChannelSuccess({
            payload: { qubescanChannel: channel },
          }),
          QubescanDashboardActions.getLastTrend(),
        ];
      })
    )
  );

  getLastTrend$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QubescanDashboardActions.getLastTrend.type),
      withLatestFrom(this.store.select(fromStore.getQubescanChannel)),
      switchMap((action) => {
        let table = [];
        let oneChannel = [];
        let fiveChannel = [];
        let tenChannel = [];
        action[1].map((channel) => {
          if (channel && table.indexOf(channel[1]) === -1) {
            table.push(channel[1]);
          }
          if (channel && channel[1] === 'oneminute') {
            oneChannel.push(channel[0]);
          } else if (channel && channel[1] === 'fiveminute') {
            fiveChannel.push(channel[0]);
          } else if (channel && channel[1] === 'tenminute') {
            tenChannel.push(channel[0]);
          }
        });
        return forkJoin([
          table.indexOf('oneminute') !== -1
            ? this.mpService.getLastMpTrends(this.mpId.toString(), oneChannel, 'oneminute')
            : of(null),
          table.indexOf('fiveminute') !== -1
            ? this.mpService.getLastMpTrends(this.mpId.toString(), fiveChannel, 'fiveminute')
            : of(null),
          table.indexOf('tenminute') !== -1
            ? this.mpService.getLastMpTrends(this.mpId.toString(), tenChannel, 'tenminute')
            : of(null),
        ]);
      }),
      switchMap(([oneValue, fiveValue, tenValue]) => {
        let qubescanChannelValue = { ...oneValue, ...fiveValue, ...tenValue };
        return [
          QubescanDashboardActions.setChannelValueSuccess({
            payload: { qubescanChannelValue: { ...qubescanChannelValue } },
          }),
        ];
      })
    )
  );

  constructor(
    private actions$: Actions<QubescanDashboardActions.QubescanDashboardActionsUnion>,
    private graphManager: GraphManagerService,
    private mpService: MeasurementPointsService,
    private authService: AuthService,
    private store: Store<fromStore.State>
  ) {}

  private chartSetName: string;
  private lastPacket: any;
  private timezone: string;
  private mpStatus: number;
  private mpId: number;
  private timestamp: any;
  private changeMpValue: boolean;
}
