import { cloneDeep } from 'lodash';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';

import { ChartsActions } from 'src/app/_store/_charts/actions';
import * as fromStore from 'src/app/_store/_reducers';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { AlarmEventNoteRequest } from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import { GraphManagerService } from 'src/app/_shared/services/graph-manager.service';
import { QubescanDashboardActions } from 'src/app/_store/_qubescan-dashboard/actions';

@Injectable()
export class ChartsEffects {
  setScope$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.setScope.type),
      map((action) => ChartsActions.setInterval())
    )
  );

  setInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.setInterval.type),
      withLatestFrom(
        this.store.select(fromStore.getScope),
        this.store.select(fromStore.getStartDate),
        this.store.select(fromStore.getEndDate),
        this.store.select(fromStore.getCommissionedWhen)
      ),
      switchMap(([action, scope, startDate, endDate, commissionedWhen]) => {
        let end;
        let start;

        if (moment(endDate, 'L').isAfter(moment()) || moment(endDate, 'L').isSame(moment().format('L'))) {
          end = moment().endOf(scope);
          start = moment().startOf(scope);
        } else {
          end = moment(startDate, 'L').endOf(scope);
          start = moment(startDate, 'L').startOf(scope);
        }

        return [
          ChartsActions.setIntervalSuccess({
            payload: {
              startDate: moment(start).format('L'),
              endDate: moment(end).format('L'),
            },
          }),
        ];
      })
    )
  );

  changeInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.changeInterval.type),
      map((action) => action.payload),
      withLatestFrom(
        this.store.select(fromStore.getScope),
        this.store.select(fromStore.getStartDate),
        this.store.select(fromStore.getEndDate),
        this.store.select(fromStore.getMp)
      ),
      switchMap(([action, scope, startDate, endDate, mp]) => {
        let end;
        let start;

        if (action.direction === 'past') {
          start = moment(startDate, 'L').subtract(1, scope);
          end = moment(start).endOf(scope);
        } else if (action.direction === 'future') {
          start = moment(startDate, 'L').add(1, scope);
          end = moment(start).endOf(scope);
        }
        return [
          ChartsActions.setIntervalSuccess({
            payload: {
              startDate: start.format('L'),
              endDate: end.format('L'),
            },
          }),
        ];
      })
    )
  );

  setYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.setYear.type),
      map((action) => action.payload),
      withLatestFrom(this.store.select(fromStore.getMp)),
      switchMap(([action, mp]) => {
        const newDate = moment()
          .tz(mp.timezone)
          .year(action.year + 1)
          .month(0)
          .date(1)
          .startOf('day')
          .subtract(30, 'minute');

        return [
          ChartsActions.setIntervalSuccess({
            payload: {
              startDate: moment(newDate.startOf('year')).tz(mp.timezone).format('L'),
              endDate: moment(newDate.endOf('year')).tz(mp.timezone).format('L'),
            },
          }),
        ];
      })
    )
  );

  setMonth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.setMonth.type),
      map((action) => action.payload),
      withLatestFrom(this.store.select(fromStore.getMp)),
      switchMap(([action, mp]) => {
        const newDate = moment().tz(mp.timezone).year(action.year).month(action.month).date(1);

        return [
          ChartsActions.setIntervalSuccess({
            payload: {
              startDate: moment(newDate.startOf('month')).tz(mp.timezone).format('L'),
              endDate: moment(newDate.endOf('month')).tz(mp.timezone).format('L'),
            },
          }),
        ];
      })
    )
  );

  setWeekDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.setWeekDay.type),
      map((action) => action.payload),
      withLatestFrom(this.store.select(fromStore.getMp), this.store.select(fromStore.getScope)),
      switchMap(([action, mp, scope]) => {
        const newDate = moment().tz(mp.timezone).year(action.year).month(action.month).date(action.day);

        return [
          ChartsActions.setIntervalSuccess({
            payload: {
              startDate: moment(newDate.startOf(scope)).format('L'),
              endDate: moment(newDate.endOf(scope)).format('L'),
            },
          }),
        ];
      })
    )
  );

  setIntervalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.setIntervalSuccess.type),
      map((actions) => actions.payload),
      withLatestFrom(this.store.select(fromStore.getScope)),
      switchMap(([action, scope]) => {
        let title: string;
        if (scope === 'days') {
          title = moment(action.startDate, 'L').format('dddd LL');
        } else if (scope === 'weeks') {
          title =
            moment(action.startDate, 'L').startOf('week').format('LL') +
            ' - ' +
            moment(action.startDate, 'L').endOf('week').format('LL');
        } else if (scope === 'months') {
          title = moment(action.startDate, 'L').format('MMMM YYYY');
        } else if (scope === 'years') {
          title = moment(action.startDate, 'L').format('YYYY');
        }
        return [ChartsActions.setTitleSuccess({ payload: { title: title } })];
      })
    )
  );

  setPrecommissionStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.setPrecommissionStart.type),
      map((action) => ChartsActions.setInterval())
    )
  );

  setEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.setEvents.type),
      withLatestFrom(this.store.select(fromStore.getMp)),
      switchMap(([action, mp]) => {
        this.chartName = action.payload.chartName;
        this.isQubescan = action.payload.isQubescan;
        this.isInsite = action.payload.isInsite;
        this.timezone = mp.timezone;
        const eventsRequest: AlarmEventNoteRequest = {
          measurementPointId: mp.measurementPointId,
          dateRangeStart: moment(action.payload.start, 'L LT').tz(mp.timezone, true).toISOString(),
          dateRangeEnd: moment(action.payload.end, 'L LT').tz(mp.timezone, true).toISOString(),
          natures: action.payload.natures,
          includeRetired: false,
          sorting: [{ column: 'triggeredWhen', desc: false }],
          offset: 0,
          count: 100000,
        };
        if (action.payload.isInsite) {
          eventsRequest.severity = 1;
        }
        if (action.payload.from === 'charts') {
          eventsRequest.includeOngoingAlarms = true;
        }
        return this.mpService
          .getAlarmsEventsNotes(eventsRequest)
          .pipe(
            switchMap((alarmEventNote) => {
              let tempAlarms = alarmEventNote.records
                .filter((alarm) => alarm.nature === 'alarm')
                .sort((a, b) => b.severity - a.severity);
              let alarms = {
                records: tempAlarms,
                totalRecordCount: tempAlarms.length,
              };

              let alarm = {
                alarm: tempAlarms.filter((alarm) => alarm.severity === 1).length,
                warning: tempAlarms.filter((alarm) => alarm.severity === 0).length,
              };

              let tempEvents = alarmEventNote.records.filter(
                (alarm) => alarm.nature === 'event' || alarm.nature === 'status' || alarm.nature === 'note'
              );
              let events = {
                records: tempEvents,
                totalRecordCount: tempEvents.length,
              };
              let note = alarmEventNote.records.filter((note) => note.nature === 'note').length;
              let status = {
                restart: alarmEventNote.records.filter(
                  (event) => event.nature === 'status' && event.typeName === 'restart'
                ).length,
              };
              let event = {
                sags: null,
                swells: null,
                impulses: null,
                interruptions: null,
                other: null,
                waveshape: null,
                rvc: null,
                snapshot: null,
              };

              event.sags = alarmEventNote.records.filter(
                (event) => event.nature === 'event' && event.typeName === 'voltageSag'
              ).length;
              event.swells = alarmEventNote.records.filter(
                (event) => event.nature === 'event' && event.typeName === 'voltageSwell'
              ).length;
              event.impulses = alarmEventNote.records.filter(
                (event) => event.nature === 'event' && event.typeName === 'highFrequencyImpulse'
              ).length;
              event.interruptions = alarmEventNote.records.filter(
                (event) => event.nature === 'event' && event.typeName === 'interruption'
              ).length;
              event.waveshape = alarmEventNote.records.filter(
                (event) => event.nature === 'event' && event.typeName === 'waveShapeChange'
              ).length;
              event.rvc = alarmEventNote.records.filter(
                (event) => event.nature === 'event' && event.typeName === 'rvc'
              ).length;
              event.snapshot = alarmEventNote.records.filter(
                (event) => event.nature === 'event' && event.typeName === 'snapshot'
              ).length;

              event.other = alarmEventNote.records.filter(
                (event) =>
                  event.nature === 'event' &&
                  event.typeName !== 'voltageSag' &&
                  event.typeName !== 'voltageSwell' &&
                  event.typeName !== 'highFrequencyImpulse' &&
                  event.typeName !== 'interruption' &&
                  event.typeName !== 'snapshot' &&
                  event.typeName !== 'restart' &&
                  event.typeName !== 'waveShapeChange' &&
                  event.typeName !== 'rvc'
              ).length;

              return [
                ChartsActions.setEventsSuccess({
                  payload: {
                    nature: { alarm: alarm, note: note, event: event, status: { restart: status.restart } },
                    events: events,
                    alarms: alarms,
                  },
                }),
              ];
            })
          )
          .pipe(
            catchError((error) => {
              return [
                QubescanDashboardActions.setValueFail({
                  payload: { error: error.message },
                }),
              ];
            })
          );
      })
    )
  );

  setEventsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChartsActions.setEventsSuccess.type),
      switchMap((action) => {
        return this.graphManager
          .updateChartSet(this.chartName, this.timezone, this.isInsite, this.isQubescan, 'effect')
          .pipe(
            switchMap((graph) => {
              return [
                QubescanDashboardActions.setValueSucces({
                  payload: { qubescanValue: cloneDeep(graph) },
                }),
              ];
            }),
            catchError((error) => {
              return [
                QubescanDashboardActions.setValueFail({
                  payload: { error: error.message },
                }),
              ];
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions<ChartsActions.ChartsActionsUnion>,
    private store: Store<fromStore.State>,
    private mpService: MeasurementPointsService,
    private graphManager: GraphManagerService
  ) {}

  private chartName: string;
  private isQubescan: boolean;
  private isInsite: boolean;
  private timezone: string;
}
