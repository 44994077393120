import { Component, HostListener, Input, Output, EventEmitter, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { MatExpansionPanel } from '@angular/material/expansion';
import { select, Store } from '@ngrx/store';
import { take, forkJoin } from 'rxjs';

import * as fromStore from 'src/app/_store/_reducers';
import { AuthService } from 'src/app/_shared/services';
import { FiltersPrefValue } from 'src/app/_shared/interface/filters-pref.interface';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { StateFilter } from 'src/app/_shared/interface/state-filter.interface';
import { OnDestroyMixin, untilComponentDestroyed } from 'src/app/_shared/classes/component-destroy.class';
import { CustomColumns } from 'src/app/_shared/interface/fleet-pref.interface';
import * as fromUser from 'src/app/_store/_reducers';
import { StringFilter } from 'src/app/_shared/interface/string-filter.interface';
import { StatusFilter } from 'src/app/_shared/interface/status-filter.interface';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent extends OnDestroyMixin implements OnDestroy, OnInit {
  @Input() from: string;
  @Input() searchFilter: string;
  @Input() locationFilter: string;
  @Input() stateOptions: Array<StateFilter>;
  @Input() stateFilter: Array<StateFilter>;
  @Input() statusOptions: Array<StatusFilter>;
  @Input() statusFilter: Array<StatusFilter>;
  @Input() alarmOptions: Array<StringFilter>;
  @Input() alarmFilter: Array<string>;
  @Input() commsOptions: Array<StringFilter>;
  @Input() commsFilter: Array<string>;
  @Input() search: boolean;
  @Input() state: boolean;
  @Input() status: boolean;
  @Input() alarm: boolean;
  @Input() comms: boolean;
  @Input() clearButton: boolean;
  @Input() disable: boolean;
  @Input() userPreferenceName: string;
  @Input() forceDisabledOptionMenu: boolean = false;

  @Output() searchFilterChange = new EventEmitter<string>();
  @Output() stateFilterChange = new EventEmitter<Array<StateFilter>>();
  @Output() statusFilterChange = new EventEmitter<Array<StatusFilter>>();
  @Output() alarmFilterChange = new EventEmitter<Array<string>>();
  @Output() commsFilterChange = new EventEmitter<Array<string>>();
  @Output() clearFilters = new EventEmitter<boolean>();

  @ViewChild('comboFilter') comboFilter: MatExpansionPanel;

  smallScreen: boolean;
  offsetSmallScreen: number;
  optionsMenuEnabled: boolean = false;

  customColumnsPref: CustomColumns = {
    tags: false,
    serialNumber: false,
  };

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.smallScreen = window.innerWidth < this.offsetSmallScreen;
  }

  constructor(private store: Store<fromStore.State>, private authService: AuthService) {
    super();
  }

  ngOnInit(): void {
    forkJoin({
      user: this.store.pipe(select(fromUser.getUser), take(1)),
      userPrefs: this.store.pipe(select(fromStore.getUserPreferences), take(1)),
    })
      .pipe(untilComponentDestroyed(this))
      .subscribe(({ user, userPrefs }) => {
        this.optionsMenuEnabled = this.forceDisabledOptionMenu === false;
        let userPrefsClone: UserPreferences = cloneDeep(userPrefs);
        if (userPrefsClone?.fleetView && userPrefs.fleetView.customColumns) {
          // Initialize component with user pref
          this.customColumnsPref = cloneDeep(userPrefsClone.fleetView.customColumns);
        } else {
          // Initialize customColumns on user preference object
          userPrefsClone = {
            ...userPrefsClone,
            ...{ fleetView: { customColumns: { ...this.customColumnsPref } } },
          };
          this.authService.savePreferences({ ...userPrefsClone }, true);
        }
      });

    this.offsetSmallScreen =
      [this.search, this.state, this.status, this.alarm, this.comms].filter(Boolean).length * 222 + 400;
    this.onResize();
    this.clearFilters.pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (this.userPreferenceName) {
        this.store
          .pipe(select(fromStore.getUserPreferences))
          .pipe(take(1))
          .subscribe((userPrefs) => {
            let userPrefsClone: UserPreferences = cloneDeep(userPrefs);
            userPrefsClone.filters[this.userPreferenceName] = {};
            if (userPrefsClone !== userPrefs) {
              this.authService.savePreferences({ ...userPrefsClone }, true);
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.userPreferenceName) {
      this.store
        .pipe(select(fromStore.getUserPreferences))
        .pipe(take(1))
        .subscribe((userPrefs) => {
          let userPrefsClone: UserPreferences = cloneDeep(userPrefs);
          let filterPrefs: FiltersPrefValue = {};
          let kpiFilters = {};

          if (this.state) {
            filterPrefs.stateFilter = this.stateFilter;
          }

          if (this.status) {
            filterPrefs.statusFilter = this.statusFilter;
          }

          if (this.alarm) {
            kpiFilters = { kpiFilters: this.alarmFilter };
          }

          if (this.comms) {
            filterPrefs.commsFilter = this.commsFilter;
          }

          filterPrefs.searchFilter = this.searchFilter;

          if (!userPrefsClone.filters) {
            userPrefsClone.filters = {};
          }
          userPrefsClone.filters[this.userPreferenceName] = filterPrefs;

          if (userPrefsClone !== userPrefs) {
            this.authService.savePreferences({ ...userPrefsClone, ...kpiFilters }, true);
          }
        });
    }
    super.ngOnDestroy();
  }

  onColumnCheckboxSelected(column: string, checked: boolean): void {
    // Set new value and propagate the changes to parent component
    this.customColumnsPref[column] = checked;
    this.store
      .pipe(select(fromStore.getUserPreferences))
      .pipe(take(1))
      .subscribe((userPrefs) => {
        let userPrefsClone: UserPreferences = cloneDeep(userPrefs);
        userPrefsClone.fleetView.customColumns[column] = checked;
        if (userPrefsClone !== userPrefs) {
          this.authService.savePreferences({ ...userPrefsClone }, true);
        }
      });
  }

  expand(event): void {
    if (window.pageYOffset <= this.comboFilter._body?.nativeElement?.offsetTop) {
      window.scroll({
        top: this.comboFilter._body.nativeElement.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  compareStates(state1: StateFilter, state2: StateFilter): boolean {
    return state1.name === state2.name;
  }

  compareStatus(status1: StatusFilter, status2: StatusFilter): boolean {
    return status1.name === status2.name;
  }
}
