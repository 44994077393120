<nav>
  <button
    mat-flat-button
    [color]="current === '/fleet' ? 'accent' : 'primary'"
    [matTooltip]="smallScreen ? '' : ('navigation.fleet' | translate)"
    matTooltipPosition="right"
    (click)="navigate(['fleet'])"
    data-cy="fleet-button"
    appEventTracking="navigation.fleetOverview"
  >
    <mat-icon svgIcon="map-marker-multiple"></mat-icon>
    <span class="menu-item-label">{{ 'navigation.fleet' | translate }}</span>
  </button>
  <button
    mat-flat-button
    [color]="current === '/dashboard' ? 'accent' : 'primary'"
    [matTooltip]="smallScreen ? '' : ('navigation.dashboard' | translate)"
    matTooltipPosition="right"
    (click)="navigate(['dashboard'])"
    appCanAccess
    [accessType]="'dashboard'"
    [disabledClass]="['disabled']"
    data-cy="dashboard-button"
    appEventTracking="navigation.dashboard"
  >
    <mat-icon class="view-list" svgIcon="dashboard-icon"></mat-icon>
    <span class="menu-item-label">{{ 'navigation.dashboard' | translate }}</span>
  </button>
  <button
    mat-flat-button
    [color]="current === '/events-list' ? 'accent' : 'primary'"
    [matTooltip]="smallScreen ? '' : ('navigation.eventsList' | translate)"
    matTooltipPosition="right"
    (click)="navigate(['events-list'])"
    appCanAccess
    [accessType]="'whenCommissioned'"
    [disabledClass]="['disabled']"
    data-cy="eventList-button"
    appEventTracking="navigation.eventList"
  >
    <mat-icon svgIcon="format-list-bulleted"></mat-icon>
    <span class="menu-item-label">{{ 'navigation.eventsList' | translate }}</span>
  </button>
  <button
    mat-flat-button
    class="charts-button"
    [color]="current === '/charts' ? 'accent' : 'primary'"
    [matTooltip]="smallScreen ? '' : ('navigation.charts' | translate)"
    matTooltipPosition="right"
    appCanAccess
    [accessType]="'whenCommissioned'"
    [disabledClass]="['disabled']"
    (click)="navigate(['charts'])"
    data-cy="charts-button"
    appEventTracking="navigation.charts"
  >
    <img class="view-list" src="./assets/images/TrendsGraph_Icon.png" />
    <span class="menu-item-label charts-label">{{ 'navigation.charts' | translate }}</span>
  </button>
  @if ((qubeScanModel$ | async) !== 'PQube 3v' && (qubeScanModel$ | async) !== 'PQube 3vr') {
  <button
    mat-flat-button
    [color]="current === '/energy-power' ? 'accent' : 'primary'"
    [matTooltip]="smallScreen ? '' : ('navigation.energyPower' | translate)"
    matTooltipPosition="right"
    (click)="navigate(['energy-power'])"
    appCanAccess
    [accessType]="'whenCommissioned'"
    [disabledClass]="['disabled']"
    data-cy="energy-power-button"
    appEventTracking="navigation.energyPower"
  >
    <img class="energy-power" src="./assets/images/meter-icon.svg" />
    <span class="menu-item-label energy-power-label">{{ 'navigation.energyPower' | translate }}</span>
  </button>
  } @if (measurementPointTypeId === 1 || measurementPointTypeId === 3) {
  <button
    mat-flat-button
    [color]="current === '/hf-emissions' ? 'accent' : 'primary'"
    [matTooltip]="smallScreen ? '' : ('navigation.hfEmissions' | translate)"
    matTooltipPosition="right"
    (click)="navigate(['hf-emissions'])"
    appCanAccess
    [accessType]="'whenCommissioned'"
    [disabledClass]="['disabled']"
    data-cy="hf-emissions-button"
    appEventTracking="navigation.hfEmissions"
  >
    <mat-icon svgIcon="hf-emission"></mat-icon>
    <span class="menu-item-label">{{ 'navigation.hfEmissions' | translate }}</span>
  </button>
  } @if (isInsite) {
  <button
    mat-flat-button
    [color]="current === '/reports' ? 'accent' : 'primary'"
    [matTooltip]="smallScreen ? '' : ('navigation.reports' | translate)"
    matTooltipPosition="right"
    (click)="navigate(['reports'])"
    appCanAccess
    [accessType]="'whenCommissioned'"
    [disabledClass]="['disabled']"
    data-cy="reports-icon"
    appEventTracking="navigation.reports"
  >
    <mat-icon
      [matBadge]="
        (recommendationCount$ | async)?.active.count > 0 ? (recommendationCount$ | async)?.active.count : null
      "
      class="badge-custom-color badge-border badge-blue"
      aria-hidden="isInsite"
      matBadgePosition="below after"
      svgIcon="clipboard-check-outline"
    >
    </mat-icon>
    <span class="menu-item-label">{{ 'navigation.reports' | translate }}</span>
  </button>
  } @if (!isInsite) {
  <button
    mat-flat-button
    [color]="current === '/report-writer' ? 'accent' : 'primary'"
    [matTooltip]="smallScreen ? '' : ('navigation.reports' | translate)"
    matTooltipPosition="right"
    (click)="navigate(['report-writer'])"
    appCanAccess
    [accessType]="'whenCommissioned'"
    [disabledClass]="['disabled']"
    data-cy="reports-writer-icon"
    appEventTracking="navigation.reports"
    #overlayOrigin="cdkOverlayOrigin"
    cdkOverlayOrigin
  >
    <mat-icon
      [matBadge]="(unseenReportResults$ | async)?.length > 0 ? 0 : null"
      class="badge-custom-color badge-border badge-blue hide-text"
      aria-hidden="isInsite"
      matBadgePosition="below after"
      svgIcon="clipboard-check-outline"
    >
    </mat-icon>
    <span class="menu-item-label">{{ 'navigation.reports' | translate }}</span>
  </button>
  @if (hintIsOpened && showHint && ((mobileMenuOpen && smallScreen) || !smallScreen)) {
  <app-hint-box
    [hint]="'reportWriter'"
    [overlayOrigin]="overlayOrigin"
    [isOpenedInput]="hintIsOpened"
    (closeHint)="hintIsOpened = false"
    (openHint)="hintIsOpened = true"
    >{{ 'hint.reportWriter' | translate }}
  </app-hint-box>
  } } @if (maintenanceAccess) {
  <button
    mat-flat-button
    [color]="current === '/maintenance' ? 'accent' : 'primary'"
    [matTooltip]="smallScreen ? '' : ('navigation.maintenance' | translate)"
    matTooltipPosition="right"
    appCanAccess
    [accessType]="'whenCommissioned'"
    (click)="navigate(['maintenance'])"
    data-cy="maintenance-icon"
    appEventTracking="navigation.maintenance"
    [disabledClass]="['disabled']"
  >
    <mat-icon svgIcon="cog"> </mat-icon>
    <span class="menu-item-label">{{ 'navigation.maintenance' | translate }}</span>
  </button>
  }
</nav>
