import { Directive, HostListener, Input, Optional } from '@angular/core';
import { EventTrackingService } from './event-tracking.service';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[appEventTracking]',
})
export class EventTrackingDirective {
  trackingEnabled: boolean = false;
  @Input('appEventTracking') trackingValue: string;
  @Input() trackingParams: object;
  constructor(private eventTrackingService: EventTrackingService) {
    this.trackingEnabled = environment.trackingEnabled;
  }

  @HostListener('click') onClick(): void {
    if (this.trackingEnabled) {
      this.eventTrackingService.trackEvent(this.trackingValue, this.trackingParams);
    }
  }
}
