import { Inject, Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment-timezone';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import {
  ChartDefinitions,
  replaceAdvancedChart,
  PQEvents,
  DynamicTrendsAlarm,
} from 'src/app/_shared/services/chart-definitions.service';
import { CustomChartTypes } from 'src/app/_shared/classes/customer-chart-types.class';
import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { ITrendsChart } from 'src/app/_shared/classes/chart.interface';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { PhaseList } from 'src/app/_shared/classes/phase-list.interface';
import { PaginatedModel } from 'src/app/_shared/interface/pagination.interface';
import {
  AlarmEventNoteResponse,
  AlarmEventNoteRequest,
} from 'src/app/_shared/interface/alarm/alarm-event-note-request.interface';
import * as fromStore from 'src/app/_store/_reducers';
import { ChartTrendData } from 'src/app/_shared/interface/chart-trend-data.interface';
import { SubscriptionData } from 'src/app/_shared/interface/subscription-data.interface';

@Injectable()
export class ChartSet implements OnDestroy {
  private name: string;
  private loadedDataStartDate: moment.Moment;
  private loadedDataEndDate: moment.Moment;
  private mCharts: ITrendsChart[];
  private chartDefinitions: ChartDefinitions;
  private dAPIService: DiligentApiService;
  private store: Store<fromStore.State>;
  private oneMinuteColumns: string[];
  private tenMinuteColumns: string[];
  private loadEventsGraph = false;
  private channelsSub$: Subscription;

  public get charts(): ITrendsChart[] {
    return this.mCharts;
  }

  public set charts(newCharts: ITrendsChart[]) {
    this.mCharts = newCharts;
  }

  constructor(
    @Inject(String) name: string,
    chartKeys: Array<any>,
    chartDefinitions: ChartDefinitions,
    dAPIService: DiligentApiService,
    phaseList: Array<PhaseList>,
    notificationsService: NotificationsService,
    @Inject(String) from: string,
    store: Store<fromStore.State>
  ) {
    this.chartDefinitions = chartDefinitions;
    this.name = name;

    let i = 0;

    if (from !== 'dashboard' && chartKeys.indexOf('pqAlarms') === -1) {
      chartKeys.splice(1, 0, 'pqAlarms');
    }
    const chartKeysLength = chartKeys.filter((x) => x !== 'pqEvents' && x !== 'notes' && x !== 'pqAlarms').length;

    this.charts = chartKeys.map((key, index) => {
      let chartHeightMultiplier: number;
      let newChart;

      if ((newChart = this.chartDefinitions[key])) {
      } else if (key === 'pqAlarms') {
        newChart = this.chartDefinitions.getDynamicTrendAlarm();
      } else {
        if (from === 'dashboard') {
          newChart = this.chartDefinitions.dashboardCustom(key);
        } else {
          newChart = this.chartDefinitions.getDynamic(key);
        }
      }

      if (!(newChart instanceof PQEvents) && !(newChart instanceof DynamicTrendsAlarm)) {
        switch (chartKeysLength) {
          case 1:
            chartHeightMultiplier = 3;
            break;
          case 2:
            chartHeightMultiplier = 2;
            break;
          case 3:
            chartHeightMultiplier = 1.5;
            break;
          default:
            chartHeightMultiplier = 1;
            break;
        }

        newChart.yAxisOptions[0].height *= chartHeightMultiplier;
        if (newChart.yAxisOptions.length > 1) {
          newChart.yAxisOptions[1].height *= chartHeightMultiplier;
        }

        newChart.spacer *= chartHeightMultiplier;
      }

      newChart.yAxis = i;
      i += newChart.yAxisOptions.length;
      // replase phase for AdvancedChart
      if (phaseList) {
        phaseList.forEach((c) => {
          if (CustomChartTypes.customChartTypes[c.chart][0] === key) {
            const chartADV = replaceAdvancedChart(newChart.name, c.phase, newChart.seriesArray, c.phase);
            chartADV.replaceChannelIds(c.phase);
            chartADV.setName(c.phase);
            newChart.yAxisOptions[0].title = chartADV.yAxisOptions[0].title;
            newChart.seriesArray = chartADV.seriesArray;
          }
        });
      }
      return newChart;
    });

    if (this.charts.indexOf(null) !== -1) {
      notificationsService.alert(
        // eslint-disable-next-line quotes
        "This Measurement Point doesn't have at least one of the channels selected in this custom tab"
      );
      this.charts = this.charts.filter((chart) => chart !== null);
    }

    this.dAPIService = dAPIService;
    this.store = store;
    this.oneMinuteColumns = this.charts
      .filter((chart) => {
        if (chart instanceof PQEvents || chart instanceof DynamicTrendsAlarm || chart === null) {
          this.loadEventsGraph = true;
          return false;
        } else return true;
      })
      .map((chart) => {
        return chart.oneMinuteChannelIds;
      })
      .reduce((prev, curr) => prev.concat(curr), []);

    this.tenMinuteColumns = this.charts
      .filter((chart) => {
        if (chart instanceof PQEvents || chart instanceof DynamicTrendsAlarm || chart === null) {
          return false;
        } else return true;
      })
      .map((chart) => {
        return chart.tenMinuteChannelIds;
      })
      .reduce((prev, curr) => prev.concat(curr), []);
  }

  public ngOnDestroy(): void {
    this.channelsSub$.unsubscribe();
  }

  public clearChartData(): void {
    this.charts.forEach((chart) => chart.clearSeriesData());
  }

  public initializeChartSetData(
    startDate: moment.Moment,
    endDate: moment.Moment,
    granularity: string,
    interval: number,
    mpID: string,
    timezone: string,
    isInsite?: boolean,
    from?: string
  ): Observable<void[]> {
    this.loadedDataStartDate = startDate;
    this.loadedDataEndDate = endDate;
    const parsedTrendsResponse = this.fetchAndParseJSONTrendsData(
      startDate,
      endDate,
      granularity,
      interval,
      mpID,
      timezone
    );
    let events: Observable<PaginatedModel<AlarmEventNoteResponse>>;
    let alarms: Observable<PaginatedModel<AlarmEventNoteResponse>>;
    if (this.loadEventsGraph) {
      const eventsRequest: AlarmEventNoteRequest = {
        measurementPointId: parseInt(mpID),
        dateRangeStart: startDate.toDate(),
        dateRangeEnd: endDate.toDate(),
        natures: ['event', 'note', 'status'],
        includeRetired: false,
        sorting: [{ column: 'triggeredWhen', desc: false }],
        offset: 0,
        count: 100000,
      };
      if (isInsite) {
        eventsRequest.severity = 1;
      }
      if (from === 'polling' || from === 'api') {
        events = this.dAPIService.getAlarmsEventsNotes(eventsRequest);
        alarms = of(null);
      } else {
        events = this.store.select((state) => state.charts.events).pipe(take(1));
        alarms = this.store.select((state) => state.charts.alarms).pipe(take(1));
      }
    }
    return forkJoin([events, parsedTrendsResponse, alarms]).pipe(
      map(([eventsResponse, trendsResponse, alarmsResponse]) => {
        return this.charts.map((chart) => {
          return chart.setSeriesData(
            isInsite,
            trendsResponse,
            eventsResponse?.records,
            alarmsResponse?.records,
            granularity,
            interval,
            startDate,
            endDate
          );
        });
      })
    );
  }

  private fetchAndParseJSONTrendsData(
    startDate,
    endDate,
    granularity,
    interval: number,
    mpID,
    timezone
  ):
    | Observable<{
        tenminute: ChartTrendData;
      }>
    | Observable<{
        oneminute: ChartTrendData;
      }> {
    let tenMinInterval = interval;
    if (interval === 1 && granularity === 'minute') {
      tenMinInterval = 10;
    }
    if (this.tenMinuteColumns.length > 0 && this.oneMinuteColumns.length > 0) {
      return forkJoin([
        this.dAPIService
          .getTrendsDataJson(startDate, endDate, granularity, this.oneMinuteColumns, mpID, 'oneminute', interval)
          .pipe(
            map((response: SubscriptionData) => {
              return {
                truncatedData: response.truncatedData,
                dateStart: response.dateStart,
                trendDataTable: this.parseTrendsJSONData(response.data, timezone, granularity),
              };
            })
          ),
        this.dAPIService
          .getTrendsDataJson(startDate, endDate, granularity, this.tenMinuteColumns, mpID, 'tenminute', tenMinInterval)
          .pipe(
            map((response: SubscriptionData) => {
              return {
                truncatedData: response.truncatedData,
                dateStart: response.dateStart,
                trendDataTable: this.parseTrendsJSONData(response.data, timezone, granularity),
              };
            })
          ),
      ]).pipe(
        map(([oneminute, tenminute]) => {
          return {
            oneminute,
            tenminute,
          };
        }),
        catchError((error): Observable<any> => {
          throw error;
        })
      );
    } else if (this.tenMinuteColumns.length > 0) {
      return this.dAPIService
        .getTrendsDataJson(startDate, endDate, granularity, this.tenMinuteColumns, mpID, 'tenminute', tenMinInterval)
        .pipe(
          map((allColumns: SubscriptionData) => {
            if (allColumns.error) {
              throw new Error(allColumns.error);
            }
            return {
              tenminute: {
                truncatedData: allColumns.truncatedData,
                dateStart: allColumns.dateStart,
                trendDataTable: this.parseTrendsJSONData(allColumns.data, timezone, granularity),
              },
            };
          }),
          catchError((error): Observable<any> => {
            throw error;
          })
        );
    }
    return this.dAPIService
      .getTrendsDataJson(startDate, endDate, granularity, this.oneMinuteColumns, mpID, 'oneminute', interval)
      .pipe(
        map((allColumns: SubscriptionData) => {
          return {
            oneminute: {
              truncatedData: allColumns.truncatedData,
              dateStart: allColumns.dateStart,
              trendDataTable: this.parseTrendsJSONData(allColumns.data, timezone, granularity),
            },
          };
        }),
        catchError((error): Observable<any> => {
          throw error;
        })
      );
  }

  private parseTrendsJSONData(
    channelDataJSON: any[],
    timezone: string,
    granularity: string
  ): { [id: string]: { columns: any[]; newData: any[] } } {
    const seriesObj = {};
    this.charts.forEach((chart) => {
      if (!(chart instanceof PQEvents)) {
        chart.seriesArray.forEach((series) => {
          seriesObj[series.id] = {
            columns: series.channelIds,
            newData: [],
          };
        });
      }
    });

    if (granularity === 'day') {
      channelDataJSON.forEach((entry) => {
        const dstTimestamp = moment(entry.time_stamp).format('YYYY-MM-DD');
        const timestamp = moment(dstTimestamp).tz(timezone, true).valueOf();

        for (const key in seriesObj) {
          if (seriesObj.hasOwnProperty(key)) {
            const row = seriesObj[key].columns.map((column) => parseFloat(entry[column]));
            seriesObj[key].newData.push([timestamp].concat(row));
          }
        }
      });
    } else {
      channelDataJSON.forEach((entry) => {
        const timestamp = moment(entry.time_stamp).valueOf();

        for (const key in seriesObj) {
          if (seriesObj.hasOwnProperty(key)) {
            const row = seriesObj[key].columns.map((column) => parseFloat(entry[column]));
            seriesObj[key].newData.push([timestamp].concat(row));
          }
        }
      });
    }
    return seriesObj;
  }
}
