import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

import { IsAuthenticatedGuard } from 'src/app/_shared/guards/route.guards';
import { CanAccessGuard, CanAccessDirective } from 'src/app/_shared/guards/can-access.guards';
import { PermissionGuard } from 'src/app/_shared/guards/permission.guard';
import { SubscriptionGuard } from 'src/app/_shared/guards/subscription.guard';
import { NotificationGuard } from 'src/app/_shared/guards/notification.guard';
import { RmsVariationAnalysisGuard } from 'src/app/_shared/guards/rms-variation-analysis.guard';
import { FirmwareUpdateGuard } from 'src/app/_shared/guards/firmwareUpdate.guard';
import { MonthsPipe } from 'src/app/_shared/pipes/months.pipe';
import { MonthsNumberToStringPipe } from 'src/app/_shared/pipes/months-number-to-string.pipe';
import { NotesSummaryPipe } from 'src/app/_shared/pipes/notes-summary.pipe';
import { NotesDetailsPipe } from 'src/app/_shared/pipes/notes-details.pipe';
import { EventDurationPipe } from 'src/app/_shared/pipes/event-duration.pipe';
import { NullValuePipe } from 'src/app/_shared/pipes/null-value.pipe';
import { ChannelNamePipe } from 'src/app/_shared/pipes/channel-name.pipe';
import { ShowFiltersNamePipe } from 'src/app/_shared/pipes/show-filters-name.pipe';
import { AccountsService } from 'src/app/_shared/services/accounts.service';
import { NewsService } from 'src/app/_shared/services/news.service';
import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { NotificationsModule } from 'src/app/_shared/modules/notifications/notifications.module';
import { EventTrackingDirective } from 'src/app/_shared/tracking/tracking.directive';
import {
  UtilitiesService,
  AuthService,
  TokenService,
  DashboardService,
  SitesService,
  PowerQualityMeasuresService,
  ChartDefinitions,
  UpdateService,
} from 'src/app/_shared/services';
import { EnumationsService } from 'src/app/_shared/services/enumerations.service';
import { DSTPipe } from 'src/app/_shared/pipes/dst.pipe';
import { FlexibleDateFormatPipe } from 'src/app/_shared/pipes/flexible-date-format.pipe';
import { RecommendationActivePipe } from 'src/app/reports-recommendations/recommendations/recommendation-status.pipe';
import { EnvironmentKeyPipe } from 'src/app/_shared/pipes/environement-key.pipe';
import { TranslateFallbackPipe } from 'src/app/_shared/pipes/translateFallback.pipe';
import { AdministrationDirective } from 'src/app/_shared/guards/administrator.guard';
import { CamelCaseSplitPipe } from 'src/app/_shared/pipes/camel-case-split.pipe';
import { FormatDatePipe } from 'src/app/_shared/pipes/format-date.pipe';
import { MpStatusNamePipe } from 'src/app/_shared/pipes/mp-status-name.pipe';
import { DashboardChannelFilterPipe } from 'src/app/_shared/pipes/dashboard-channel-filter.pipe';
import { Significant3NumberPipe } from 'src/app/_shared/pipes/significant-number.pipe';
import { SuperBowlPipe } from 'src/app/_shared/pipes/super-bowl.pipe';
import { CapitalizePipe } from 'src/app/_shared/pipes/capitalize.pipe';
import { PowerUnitPipe } from 'src/app/_shared/pipes/power-unit.pipe';
import { PrefixCalPipe } from 'src/app/_shared/pipes/prefix-cal.pipe';
import { MaxUnitPipe } from 'src/app/_shared/pipes/max-unit.pipe';
import { KToMPipe } from 'src/app/_shared/pipes/k-to-m.pipe';
import { DashValuePipe } from 'src/app/_shared/pipes/dash-value.pipe';
import { TextMaxLengthPipe } from 'src/app/_shared/pipes/text-max-length.pipe';

@NgModule({
  imports: [CommonModule, RouterModule, HttpClientModule, NotificationsModule, MatIconModule, TranslateModule],
  declarations: [
    AdministrationDirective,
    MonthsPipe,
    MonthsNumberToStringPipe,
    NotesSummaryPipe,
    NotesDetailsPipe,
    EventDurationPipe,
    FormatDatePipe,
    DSTPipe,
    NullValuePipe,
    DashValuePipe,
    PowerUnitPipe,
    PrefixCalPipe,
    MaxUnitPipe,
    KToMPipe,
    SuperBowlPipe,
    ChannelNamePipe,
    RecommendationActivePipe,
    ShowFiltersNamePipe,
    EnvironmentKeyPipe,
    CanAccessDirective,
    FlexibleDateFormatPipe,
    TranslateFallbackPipe,
    CamelCaseSplitPipe,
    CapitalizePipe,
    MpStatusNamePipe,
    DashboardChannelFilterPipe,
    Significant3NumberPipe,
    EventTrackingDirective,
    TextMaxLengthPipe,
  ],
  exports: [
    RouterModule,
    CommonModule,
    TranslateModule,
    MatIconModule,
    ReactiveFormsModule,
    AdministrationDirective,
    MonthsPipe,
    MonthsNumberToStringPipe,
    NotesSummaryPipe,
    NotesDetailsPipe,
    EventDurationPipe,
    FormatDatePipe,
    DSTPipe,
    NullValuePipe,
    DashValuePipe,
    PowerUnitPipe,
    PrefixCalPipe,
    MaxUnitPipe,
    KToMPipe,
    SuperBowlPipe,
    ChannelNamePipe,
    ShowFiltersNamePipe,
    RecommendationActivePipe,
    EnvironmentKeyPipe,
    FlexibleDateFormatPipe,
    TranslateFallbackPipe,
    CamelCaseSplitPipe,
    CapitalizePipe,
    CanAccessDirective,
    MpStatusNamePipe,
    DashboardChannelFilterPipe,
    Significant3NumberPipe,
    EventTrackingDirective,
    TextMaxLengthPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        UtilitiesService,
        DiligentApiService,
        ChartDefinitions,
        AuthService,
        AccountsService,
        TokenService,
        DashboardService,
        SitesService,
        PowerQualityMeasuresService,
        IsAuthenticatedGuard,
        CanAccessGuard,
        PermissionGuard,
        SubscriptionGuard,
        NotificationGuard,
        RmsVariationAnalysisGuard,
        FirmwareUpdateGuard,
        DatePipe,
        MonthsNumberToStringPipe,
        NotesSummaryPipe,
        NotesDetailsPipe,
        EventDurationPipe,
        NullValuePipe,
        DashValuePipe,
        PowerUnitPipe,
        PrefixCalPipe,
        MaxUnitPipe,
        KToMPipe,
        SuperBowlPipe,
        ChannelNamePipe,
        DSTPipe,
        ShowFiltersNamePipe,
        MpStatusNamePipe,
        NewsService,
        EnumationsService,
        TextMaxLengthPipe,
      ],
    };
  }

  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, update: UpdateService) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')); // Or whatever path you placed mdi.svg at
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/powerside-icon.svg'));
    matIconRegistry.addSvgIcon('doc-archive', domSanitizer.bypassSecurityTrustResourceUrl('./assets/archive_icon.svg'));
    matIconRegistry.addSvgIcon(
      'pqube',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/PQUBE3_Blue_Icon.svg')
    );
    matIconRegistry.addSvgIcon(
      'psl',
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/insite-logo_icon.svg')
    );
    matIconRegistry.addSvgIcon(
      'bad-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-red.svg')
    );
    matIconRegistry.addSvgIcon(
      'good-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-green.svg')
    );
    matIconRegistry.addSvgIcon(
      'insufficient-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-blue.svg')
    );
    matIconRegistry.addSvgIcon(
      'no-data',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-white.svg')
    );
    matIconRegistry.addSvgIcon(
      'uncommissioned',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-circle-grey.svg')
    );
    matIconRegistry.addSvgIcon(
      'delete-circle-plain',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/delete-circle-plain.svg')
    );
    matIconRegistry.addSvgIcon(
      'firmware-update',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/images/firmware-update.svg')
    );
  }
}
